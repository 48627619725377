<template>
  <component :is="'PageWithColoredCenteredBox'">
    <div class="page-measure-40-cm">
      <div class="measure-distance-container">
        <h2 class="h-2" v-html="this.$t('page-measure-40-cm.title')"></h2>
        <p class="margin-top-s">{{ getDistanceText() }}</p>
        <img class="margin-top-l img-responsive" :src="getDistanceImage()"/>
        <a class="margin-top-s whats-distance" v-on:click="showWhatsDistanceModal()"
           v-if="is40cm()">{{ this.$t('page-measure-40-cm.whats-40-cm') }}</a>
        <button class="btn primary-bg small-btn margin-top-l"
                v-on:click="this.$changePageWithAnalyticsStep(this.$store, 'PageChecklist')">
          {{ this.$t('page-measure-40-cm.button-text') }}
        </button>
      </div>
    </div>
  </component>
</template>

<script>
import PageWithColoredCenteredBox from "@/components/pages/generic/PageWithColoredCenteredBox";
import Whats40cm from "@/components/Whats40cm";
import distance2m from '@/assets/img/distance-2m.svg';
import distance40cm from '@/assets/img/distance-40cm.svg';
import tablet40cm from '@/assets/img/tablet-40cm.svg';
import tablet2m from '@/assets/img/tablet-2m.svg';
import smartphone2m from '@/assets/img/smartphone-2m.svg';
import smartphone40cm from '@/assets/img/smartphone-40cm.svg';

import {getDeviceType} from "@/utils/utils";
import {deviceTypes} from "@/constants";

export default {
  components: {PageWithColoredCenteredBox},
  methods: {
    getDistanceImage() {

      if (this.$store.state.distance === -1) this.$store.commit('changePage', 'PageDistance');
      
      if(this.$store.state.distance === 1) {

        switch (getDeviceType()) {
            case deviceTypes.MOBILE:
              return smartphone2m
            case deviceTypes.TABLET:
              return tablet2m
            default:
              return distance2m 
          }
      } else {
        // Cas 40cm
        switch (getDeviceType()) {
            case deviceTypes.MOBILE:
              return smartphone40cm
            case deviceTypes.TABLET:
              return tablet40cm
            default:
              return distance40cm
          }
      }
    },
    showWhatsDistanceModal() {

      this.$store.commit('showModal',
          {
            componentToShowInsideModal: Whats40cm,
            argumentsToPassToAboveComponent: {},
            modalButtonActionText1: this.$t('global.ok'),
            onModalButton1Click: () => {
              this.$store.commit('hideModal');
            },
          }
      );
    },
    is40cm() {
      return this.$store.state.distance === 0;
    },
    getDistanceText() {
      if (this.$store.state.distance === -1) this.$store.commit('changePage', 'PageDistance');

      return this.$store.state.distance === 0 ? this.$t("page-measure-40-cm.40-cm") : this.$t("page-measure-40-cm.2-m");
    }
  },
};
</script>
